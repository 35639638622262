/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.navbar-brand {
  background-color: white;
  font-size: 2.5rem;
}

.header {
  width: 100%;
  text-align: center !important;
  overflow: hidden;
}

.logo {
  width: 250px;
}
.MuiDialog-paperWidthSm {
  max-width: 75rem;
}
.container {
  margin-top: 6.25rem;
}
.previews-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.previews-dialog .MuiDialog-paper {
  width: 100%;
  max-width: none;
}

.preview-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.VKIForm {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  font-size: 0.9375rem;
  vertical-align: top;
  border-radius: 0rem 0rem 1rem 1rem;
  border-top: none;
  border-style: groove;
}

.VKIFormLoading {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  font-size: 0.9375rem;
  vertical-align: top;
  border-radius: 1rem;
  border-style: groove;
}

.VKIFormHeader {
  margin: 0rem 0.625rem 0rem 0.625rem;  
  padding: 0rem 0rem 0.5rem 0.5rem;
  font-size: 0.9375rem;
  vertical-align: top;
  border-radius: 1rem 1rem 0rem 0rem;
  border-style: groove;
  border-bottom: none;
}

.VKIFormHeaderLoading {
  display:none;
}

.css-1ex1afd-MuiTableCell-root
{
  line-height: 1;
}
td, tr
{
  padding: 1px !important;
}

.input-label,
.default-label-rassen,
.default-label-vbnummers,
.default-label-externslachthuis {
  font-size: 1rem !important;
}

.input-label-shrink {
  font-size: 1.40rem !important;
  // font-weight: bold !important;
}
.VKIOverzicht {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  font-size: 0.9375rem;
  vertical-align: top;
}

.navbar, .navbar-brand, .nav-link
{
  padding-top: 0rem;
  padding-bottom: 0rem;
  color: #2B2B67
}

.css-sghohy-MuiButtonBase-root-MuiButton-root    {
  //background-color: #2B2B67 !important;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root
{
  color: white !important;
}

.groupedVKI {
  background-color: rgb(219, 224, 250);
}
.vkiSetting
{
  width: 25rem;
}
.menuButton
{
  float: right;
}
.headerOverviewVKI {
  background-color: #1976d2;
  color: white !important;
}


.tableHeader {
  color: white !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vkiTable {
  overflow-x: scroll !important;
  width: 100%;
}

.VKIComponentCenter {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  padding-top: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VKIComponentCenterLoading {
  display:none;
}

.VKIFormContainer {
  vertical-align: top;
  width: 100%;
}

textarea {
  font-size: 0.625rem;
}

.Ingevuld {
  background-color: #2B2B67;
  padding: 1.25rem;
}

input {
  background-color: white;
  color: black;
}

.default-column-rassen,
.default-column-vbnummers {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.titleTest {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.expand-button:focus {
  outline: none;
}

.expand-button .MuiSvgIcon-root {
  transition: transform 0.3s ease-in-out;
}

.expand-button.expanded .MuiSvgIcon-root {
  transform: rotate(180deg);
}
.checkVKIInfo
{
  padding-left: 15px;
}
.form-header {
  padding: 0.9375rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.625rem;
}
.zoom
{
  float: right;
  width: 35px;
}
.form-header-field-DatumVanInvullen,
.form-header-field-Volgnummer {
  grid-column: 1;
}


.form-header-field-Opzetdatum,
.form-header-field-Stalnummer,
.form-header-field-Bedrijfslocatie {
  grid-column: 2;
}

.form-header-field-Opzetdatum {
  grid-row: 1;
}

.form-header-field-Stalnummer {
  grid-row: 2;
}

.form-header-field-Bedrijfslocatie {
  grid-row: 3;
}

.default-section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.9375rem;
}

.VoerDateFields {
  height: 6.8125rem !important;
  margin-bottom: -0.25rem !important;
}

.VoerDateFields svg {
  padding: 0 !important;
  font-size: 1.2rem !important;
}

.VoerDateFields input {
  padding: 0.5625rem 0.3125rem 0.5625rem 0.3125rem !important;
  height: 1.4375em !important;
  
}

.VoerDateFields button:focus{
  border-color: transparent;
  outline: 0;
}
.datepickerWidth140px {
  width: 140px !important;
}
.datepickerWidth270px {
  width: 270px !important
}

.gezondheidGebruiktGeneesmiddelError {
  margin-bottom: 2.5rem !important;
}

.gezondheidGebruiktGeneesmiddelErrorSelectbox {
  margin-bottom: 0rem !important;
  width: 7.5rem;
  text-align: center;
}

.gezondheidDateFields {
  height: 6.8125rem !important;
  margin-bottom: -0.3rem !important;
}

.gezondheidDateFields input {
  height: 2.125rem !important;
  padding: 0.3125rem !important;
  width: 8.75rem !important;
}

.gezondheidDateFields svg {
  padding: 0 !important;
  font-size: 1.2rem !important;
}

.gezondheidDateFields button:focus button:active{
  border-color: transparent;
  outline: 0;
  padding: 0rem !important;
}

.vaccinsDateFields {
  height: 6.58rem !important;
  width: 8.75rem !important;
}

.vaccinsDateFields input {
  height: 2.125rem !important;
  padding: 0.3125rem !important;
  margin: -2px !important;
}

.vaccinsDateFields svg {
  padding: 0 !important;
  font-size: 1.2rem !important;
}

.vaccinsDateFields button:focus{
  border-color: transparent;
  outline: 0;
  padding: 0rem !important;
}

.leveringDateFields {
  width: 11.32% !important;
  margin-bottom: 1.4rem !important;
}

.leveringDateFields input{
  padding: 0rem 0rem 0rem 0.5rem !important;
  height: 1.875rem !important;
  width: 8.75rem !important;
}

.leveringDateFields svg{
  padding: 0 !important;
  font-size: 1.2rem !important;
}

.leveringDateFields button{
  margin-left: 0 !important;
  padding: 0!important;
}

.leveringDateFields button:focus{
  border-color: transparent;
  outline: 0;
}

.default-row {
  display: flex;
  align-items: center;
  //margin-bottom: 3.75rem;
  margin-bottom: 1rem;
  margin-right: 0.3125rem;
}

.default-label {
  width: 25%;
  margin-top: 1.25rem;
}

.test .MuiFormHelperText-root {
  /* Add styles for the helper text, for example: */
  color: #f44336; /* Red text color */
  font-size: 0.8rem;
}

.default-label-levering {
  margin-right: 1.25rem;
  font-weight: bold;
}

.default-label-splitter {
  float: right;
  margin-right: 1.875rem;
}
.default-field-NaamDierenarts{
  width: 62%;
}

.default-field-Naam,
.default-field-Adres,
.default-field-Woonplaats,
.default-field-Kipnummer,
.default-field-Email,
.default-field-Vestigingsplaats,
.default-field-Plaats,
.default-field-Ras,
.default-field-Soortpluimvee,
.default-field-NaamBroederij,
.default-row-vbnummers,
.default-row-GecumuleerdeDagelijkseMortaliteit,
.default-row-UitvalEersteWeek,
.default-row-TotaleDagelijkseMortaliteit,
.default-row-AantalOpgezetteDieren,
.default-field-Praktijk {
  width: 100%;
}

.default-field-externslachthuis-checkbox {
  float: left;
}

.default-row-externslachthuis {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}

.default-label-externslachthuis {
  width: 20%;
}

.default-row-rassen {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  width: 100%;
}

.default-label-rassen, 
.default-label-vbnummers {
  width: 17%;
}

.default-field-Ras,
.default-field-vbnummers {
  margin-right: 0.625rem;
}
.default-field-vbnummers{ 
  width: 100%;
}

.default-field-vbnummers-input {
  width: 16,666666666666666666666666666667%;
  border: 1.5px solid #ccc; 
  border-radius: 5px;     
  padding: 8px;           
  font-size: 14px;   
  height: 30px !important;
}

.default-field-vbnummers-input:hover {
  border-color: grey;
}

.default-field-vbnummers-input .Mui-focused {
  border-color: #296ec6;
}

.default-field-Ras-input{
  width: 100%;
  margin-top: 0.625rem;
}

.input-label-shrink {
  transform: translate(0, 1.5px) scale(1); /* Adjust label position when shrinking */
}

.default-label-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.default-row-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.default-label-container-label {
  width: 20%;
  margin-top: 1.25rem;
}

.default-row-content-row {
  display: flex;
  flex-direction: row;
  width: max-content;
  margin-right: 0.625rem;
  width: 100%;
}

.default-row-content-row-input {
  width: 10%;
  text-align: center;
}

.default-row-content-selectbox {
  width: 7.5rem;
  text-align: center;
  margin-bottom: 4.0625rem !important;
}

.coccidiostaticum-selectbox-error {
  margin-bottom: 0rem !important;
}

.default-row-content-selectbox-error-coccidiostaticum {
  margin-bottom: 2.5rem !important;
}

.default-row-content-selectbox-error-vaccins {
  width: 7.5rem;
  text-align: center;
  margin-bottom: 0 !important;
}

.custom-error-message-methode {
  margin-bottom: 2.5rem !important;
}

.default-row-content-label {
  width: 100%;
  margin-bottom: 1.25rem !important;
}

.default-row-content-number-input-wettelijk-wachttermijn {
  width: 7.5rem;
}

.default-row-content-date-input {
  width: 7.5rem;
}

.default-row-content-date-input-setError {
  width: 7.5rem;
  height: 1.875rem;
  margin-bottom: 5rem !important;
}

.default-row-container-columns {
  display: flex;
  flex-direction: row;
  width: max-content;
  justify-content: space-between;
  width: 100%;
}

.default-row-content-columns-data {
  display: flex;
  flex-direction: column;
  width: max-content;
  margin-bottom: 1.25rem;
}

.error{
  color: red;
}
.default-row-content-selectbox-error {
  width: 7.5rem;
  // niet het mooist wat er is maar zo allignt het wel mooier.
  margin-bottom: -0.1875rem !important;
  border: 0.0625rem solid red;
}

.default-row-content-number-input,
.default-row-content-date-input {
  margin-bottom: 5rem !important;
}

.default-row-content-typography {
  margin-top: 0.625rem !important;
  margin-bottom: 4.375rem !important;
  text-align: center !important;
  font-weight: bold !important;
}

.default-row-content-selectbox-salmonella {
  width: 50%;
  margin-bottom: 0.9375rem;
}

.default-row-content-label {
  font-weight: bold;
}

.default-row-container-columns-salmonella,
.default-row-container-columns-opmerkingen,
.default-row-container-columns-duitseOndertekening {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.default-row-content-columns-data-salmonella,
.default-row-content-columns-data-opmerkingen {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  align-items: center;
  flex: 1;
}

.default-row-content-columns-data-duitseOndertekening {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  flex: 1;
}

.default-row-content-label-salmonella,
.default-row-content-label-opmerkingen,
.default-row-content-label-duitseOndertekening {
  align-self: center;
  display: flex;
  margin-bottom: 1.25rem;
  font-weight: bold;
}

.default-row-content-aufgezogenIn-input {
  width: 100%;
  margin-top: 0.625rem;
}

.aanvangladen-Time-field {
  width: 12.5rem;
}
.time-field-aanvangladen > div {
  height: 1.875rem !important;
  width: 5rem !important;
  margin: 0rem 0.625rem 0rem 0.625rem !important;
}

.custom-number-textfield-levering {
  width: 5rem !important;
  margin: 0rem 0.625rem 0rem 0.625rem !important;
}

.custom-error-message {
  color: red !important;
  font-size: 0.75rem; 
  margin-top: -0.625rem;
  top: 100%;
  position: absolute;
}

.custom-error-message-kwaliteitstrooisel {

}

.strooisekwaliteit-select-container,
.methode-select-container {
  flex-direction: column;
}

.custom-error-message-vaccins {
  color: red !important;
  font-size: 0.75rem;
}

.select-container-levering {
  width: 9.375rem;
}

.selected-option-levering {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-container-dierenarts {
  width: 11.375rem;
}

.selected-option-dierenarts {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.default-label-strookwaliteit {
  margin-right: 1.25rem;
  font-weight: bold;
}

/* MOBILE */
@media (max-width: 48rem) {
  .container {
    margin: 1.875rem;
  }

  .default-row-container-columns-salmonella,
  .default-row-container-columns-opmerkingen {
    flex-direction: column;
  }

  .default-row-content-columns-data-salmonella,
  .default-row-content-columns-data-opmerkingen {
    align-items: center;
    margin-bottom: 1.25rem;
  }

  .default-row-content-label-salmonella,
  .default-row-content-label-opmerkingen {
    text-align: center;
    margin-bottom: 1.25rem;
  }

  .default-row-content-selectbox-salmonella {
    width: 100%;
    margin-bottom: 0.9375rem;
  }

  .default-row-content-selectbox,
  .default-row-content-label,
  .default-row-content-typography,
  .default-row-content-date-input {
    margin-bottom: 0.625rem;
  }

  .default-row-content-number-input-wettelijk-wachttermijn,
  .default-row-content-date-input,
  .default-row-content-number-input,
  .default-row-content-selectbox {
    width: 100%;
  }

  .default-row-content-row {
    flex-direction: column;
    width: 100%;
  }

  .default-row-content-row-input {
    width: 100%;
    text-align: left;
  }

  .default-label,
  .default-label-levering {
    width: 100%;
    margin-top: 0;
  }

  .default-field-Naam,
  .default-field-Adres,
  .default-field-Woonplaats,
  .default-field-Kipnummer,
  .default-field-Email,
  .default-field-Vestigingsplaats,
  .default-field-Plaats,
  .default-field-Ras,
  .default-field-Soortpluimvee,
  .default-field-NaamBroederij,
  .default-row-vbnummers,
  .default-row-GecumuleerdeDagelijkseMortaliteit,
  .default-row-UitvalEersteWeek,
  .default-row-TotaleDagelijkseMortaliteit,
  .default-row-AantalOpgezetteDieren,
  .default-field-Praktijk {
    width: 100%;
  }

  .default-label-container,
  .default-row-container {
    flex-direction: column;
    width: 100%;
  }

  .default-label-container-label,
  .default-label-rassen,
  .default-label-vbnummers {
    width: 100%;
    margin-top: 0;
  }

  .default-row-content-columns-data {
    flex-direction: row;
  }

  .default-row-content-columns-data,
  .default-row-content-label,
  .default-row-content-typography,
  .default-row-content-date-input {
    margin-bottom: 0.625rem;
  }
}
